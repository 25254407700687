<template>
  <TitleContentPanel title="楼宇概况" height="100%">
    <template #append>
      <span @click="handleDesc">概况简介</span>
    </template>

    <div class="left-1-wrapper">
      <div class="total-count">
        <span class="text">楼宇总数</span>
        <CountTo class="count" :startVal="0" :endVal="dataObj.buildingCount" :duration="1000" />
        <span class="text">栋</span>
      </div>

      <div class="key1-key2-key3">
        <LabelCount class="label-count" label="注册企业总数" :count="dataObj.regCpyCount" />
        <LabelCount class="label-count" label="入驻企业总数" :count="dataObj.entCpyCount" />
        <LabelCount class="label-count" label="入驻且注册总数" :count="dataObj.regEntCpyCount" />
      </div>

      <!-- <div class="key4-key5-key6">
        <LabelCount class="label-count" label="楼宇总数" :count="dataObj.key4" />
        <LabelCount class="label-count" label="亿元楼宇" :count="dataObj.key5" />
        <LabelCount class="label-count" label="特色楼宇" :count="dataObj.key6" />
      </div> -->
    </div>
  </TitleContentPanel>

  <Modal :isShow="dataObj.showDesc">
    <div class="desc-title">
      <div class="title-close">
        <span class="title">市南区楼宇经济基本情况</span>
        <div class="net-admin-btn">
          <Icon class="close" name="close" @click="dataObj.showDesc = false" />
        </div>
      </div>

      <div class="desc-content">
        <div class="part">
          {{ part1 }}
        </div>
        <br />
        <div class="part">
          {{ part2 }}
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import LabelCount from '@/components/ListView/LabelCount'
import TitleContentPanel from '@/components/TitleContentPanel'
import { ref, computed, watch, reactive } from 'vue'
import { useStore } from 'vuex'
import { CountTo } from 'vue-count-to2'
import { aggregateList } from '@/api/aggregate'
import Modal from '@/components/Modal'
import Icon from '@/components/IconSvg'
import { getIndustryListLevel0 } from '@/api/list'
export default {
  name: 'Left1',
  components: { LabelCount, TitleContentPanel, CountTo, Modal, Icon },
  setup: () => {
    const store = useStore()
    const currentStreetId = computed(() => store.state.common.currentStreetId)
    const currentBuildingTag = computed(() => store.state.buildingResources.currentBuildingTag)
    const currentIndustryType = computed(() => store.state.industrialEconomy.currentIndustry)
    const options = {}
    const part1 = ref('')
    const part2 = ref('')

    const dataObj = reactive({
      buildingCount: 0, // 楼宇总数
      regCpyCount: 0, // 注册企业
      entCpyCount: 0, // 入驻企业
      regEntCpyCount: 0, // 注册且入驻
      showDesc: false
    })

    const fetchData = (options = {}) => {
      const { streetId, buildingTypeLabel, currentIndustryType } = options

      const params = {
        buildingCount: {
          cmdList: [
            { $match: { 'data.name': { $exists: 1 } } },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $count: 'count' }
          ],
          templateName: 'building_information'
        },
        regCpyCount: {
          cmdList: [
            { $match: {} },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $group: { _id: '', sum: { $sum: '$stats.regCpyCount' } } },
            { $project: { _id: 0, sum: 1 } }
          ],
          templateName: 'building_information'
        },
        entCpyCount: {
          cmdList: [
            { $match: {} },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $group: { _id: '', sum: { $sum: '$stats.enterCpyCount' } } },
            { $project: { _id: 0, sum: 1 } }
          ],
          templateName: 'building_information'
        },
        regEntCpyCount: {
          cmdList: [
            { $match: {} },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $group: { _id: '', sum: { $sum: '$stats.regEnterCpyCount' } } },
            { $project: { _id: 0, sum: 1 } }
          ],
          templateName: 'building_information'
        }
      }

      if (streetId) {
        params.buildingCount.cmdList[0].$match['data.street'] = `NumberLong(${streetId})`
        params.regCpyCount.cmdList[0].$match['data.street'] = `NumberLong(${streetId})`
        params.entCpyCount.cmdList[0].$match['data.street'] = `NumberLong(${streetId})`
        params.regEntCpyCount.cmdList[0].$match['data.street'] = `NumberLong(${streetId})`
      }

      if (buildingTypeLabel) {
        params.buildingCount.cmdList[0].$match['data.buildingTypeLabel'] = buildingTypeLabel
        params.regCpyCount.cmdList[0].$match['data.buildingTypeLabel'] = buildingTypeLabel
        params.entCpyCount.cmdList[0].$match['data.buildingTypeLabel'] = buildingTypeLabel
        params.regEntCpyCount.cmdList[0].$match['data.buildingTypeLabel'] = buildingTypeLabel
      }

      if (currentIndustryType) {
        params.buildingCount.cmdList[0].$match['data.industryType'] = currentIndustryType
        params.regCpyCount.cmdList[0].$match['data.industryType'] = currentIndustryType
        params.entCpyCount.cmdList[0].$match['data.industryType'] = currentIndustryType
        params.regEntCpyCount.cmdList[0].$match['data.industryType'] = currentIndustryType
      }

      aggregateList(params).then(res => {
        dataObj.buildingCount = res.data.buildingCount[0]?.count || 0
        dataObj.regCpyCount = res.data.regCpyCount[0]?.sum || 0
        dataObj.entCpyCount = res.data.entCpyCount[0]?.sum || 0
        dataObj.regEntCpyCount = res.data.regEntCpyCount[0]?.sum || 0
      })
    }

    const getInfo = industryMap => {
      const params = {
        special: {
          cmdList: [
            { $match: { 'data.buildingTypeLabel': { $in: ['特色楼宇'] } } },
            { $count: 'count' }
          ],
          templateName: 'building_information'
        },
        buildingInfo: {
          cmdList: [
            { $match: { isDeleted: -1 } },
            { $match: { 'data.name': { $exists: 1 } } },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            {
              $group: {
                _id: 'null',
                counts: { $sum: 1 },
                totalArea: { $sum: { $toDouble: '$data.totalArea' } },
                leaveArea: { $sum: { $sum: '$data.leaveArea' } }
              }
            }
          ],
          templateName: 'building_information'
        },
        regCpyCounts: {
          cmdList: [
            { $match: {} },
            { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
            { $group: { _id: '', counts: { $sum: '$stats.regCpyCount' } } },
            { $project: { _id: 0, counts: 1 } }
          ],
          templateName: 'building_information'
        },
        cpyCounts: {
          cmdList: [{ $group: { _id: 'null', counts: { $sum: 1 } } }],
          templateName: 'enterprise_information'
        },
        streetInfo: {
          cmdList: [
            { $project: { name: '$data.name', buildingCounts: '$stats.buildingCounts' } },
            { $sort: { buildingCounts: -1 } },
            { $limit: 3 }
          ],
          templateName: 'street_information'
        },
        industryCpyCounts: {
          cmdList: [
            { $match: { 'data.type': '楼宇', 'data.year': '2020年' } },
            { $group: { _id: '$data.industry', regCpyCounts: { $sum: '$data.regCpyCount' } } },
            { $sort: { regCpyCounts: -1 } },
            { $limit: 3 }
          ],
          templateName: 'stats_cpy_information'
        },
        industryCpyTax: {
          cmdList: [
            { $match: { 'data.type': '楼宇', 'data.year': '2020年', 'data.id': { $ne: 'null' } } },
            {
              $group: {
                _id: '$data.industry',
                regCpyTax: { $sum: { $toDouble: '$data.regCpyTax' } }
              }
            },
            { $sort: { regCpyTax: -1 } },
            { $limit: 3 }
          ],
          templateName: 'stats_cpy_information'
        },
        buildingCpyTax: {
          cmdList: [
            { $match: { 'data.type': '楼宇', 'data.year': '2020年', 'data.id': { $ne: 'null' } } },
            { $group: { _id: 'null', regCpyTax: { $sum: { $toDouble: '$data.regCpyTax' } } } }
          ],
          templateName: 'stats_cpy_information'
        }
      }

      aggregateList(params).then(res => {
        const {
          buildingInfo,
          buildingCpyTax,
          industryCpyCounts,
          industryCpyTax,
          cpyCounts,
          regCpyCounts,
          streetInfo,
          special
        } = res.data

        const key1 = buildingInfo[0].counts
        const key2 = (buildingInfo[0].totalArea / 10000).toFixed()
        const key3 = ((1 - buildingInfo[0].leaveArea / buildingInfo[0].totalArea) * 100).toFixed(2)
        const key4 = regCpyCounts[0].counts
        const key5 = ((regCpyCounts[0].counts / cpyCounts[0].counts) * 100).toFixed(2)
        const key6 = streetInfo.map(d => d.name).join('、')
        const key7 = streetInfo.map(d => d.buildingCounts).join('、')
        const key8 = industryCpyCounts.map(d => `${industryMap[d.id]}`).join('、')
        const key9 = industryCpyCounts
          .map(d => `${((d.regCpyCounts / regCpyCounts[0].counts) * 100).toFixed(2)}%`)
          .join('、')
        const key10 = industryCpyTax.map(d => `${industryMap[d.id]}`).join('、')
        const key11 = industryCpyTax
          .map(d => `${((d.regCpyTax / buildingCpyTax[0].regCpyTax) * 100).toFixed(2)}%`)
          .join('、')
        const key12 = special[0]?.count || 0

        part1.value = `全区纳入统计口径已投入使用楼宇${key1}余座，商务楼宇总建筑面积近${key2}万平方米，整体入驻率约${key3}%，全区商务楼宇注册在营企业${key4}家，占全区在营企业的${key5}%。在统商务楼宇主要分布在${key6}街道，分别${key7}个。`
        part2.value = `商务楼宇内，按照行业个数分布，${key8}位居前三名，分别占比${key9}；按照全口径税收分布，${key10}位居前三名，分别占比${key11}。2020年税收过亿元（含）楼宇21座、经评特色楼宇${key12}个。`
      })
    }

    fetchData()

    getIndustryListLevel0().then(res => {
      const list = res.data
      const map = {}
      list.forEach(d => {
        map[d.code] = d.name
      })
      getInfo(map)
    })

    watch(currentStreetId, streetId => {
      options.streetId = streetId
      fetchData(options)
    })

    watch(currentBuildingTag, tag => {
      switch (tag) {
        case 1:
          options.buildingTypeLabel = { $in: ['亿元楼宇'] }
          break
        case 2:
          options.buildingTypeLabel = { $in: ['重点楼宇'] }
          break
        case 3:
          options.buildingTypeLabel = { $in: ['特色楼宇'] }
          break
        default:
          delete options.buildingTypeLabel
          break
      }
      fetchData(options)
    })

    watch(currentIndustryType, type => {
      if (type && type !== '0') {
        options.currentIndustryType = { $in: [type] }
      } else {
        delete options.currentIndustryType
      }
      fetchData(options)
    })

    const handleDesc = () => {
      dataObj.showDesc = true
    }
    return { dataObj, handleDesc, part1, part2 }
  }
}
</script>

<style lang="less" scoped>
@bgcColor: #1c1c24;
@textColor: fade(#fff, 85%);
@titleBgc: #1b1c1c;

.left-1-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #fff;

  .total-count {
    .text {
      font-size: 16px;
      margin: 0 10px;
    }

    .count {
      font-size: 30px;
    }
  }

  .key1-key2-key3,
  .key4-key5-key6 {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

.desc-title {
  width: 900px;
  height: 550px;
  background-color: #0b0f1a;
  // padding: 20px;
  overflow: hidden;
  padding: 0 30px 30px;

  .title-close {
    height: 75px;
    color: @textColor;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 24px;
    // padding-left: 20px;

    .net-admin-btn {
      display: flex;
      align-items: center;

      .close {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        color: @textColor;
        cursor: pointer;
      }
    }
  }

  .desc-content {
    background-color: #161e31;
    border-radius: 6px;
    height: calc(100% - 75px);
    padding: 45px 30px;

    .part {
      color: #fff;
      font-size: 18px;
      line-height: 2;
      text-indent: 2em;
      letter-spacing: 2px;
    }
  }
}
</style>
