<template>
  <TitleContentPanel :tabs="tabs" height="100%" @onPanelClick="handleTabClick">
    <div class="left-1-wrapper">
      <ul>
        <li v-for="(item, index) in dataList" :key="index" @click="handleItemClick(item)">
          <div class="index-label">
            <span class="index" :class="{ top3: index < 3 }">{{ index + 1 }}</span>
            <div class="label">{{ item.data.name }}</div>
          </div>
          <div class="count">{{ getCurrentCount(item) }} 家</div>
        </li>
      </ul>
    </div>
  </TitleContentPanel>

  <Modal :isShow="showDetail">
    <div class="item-detail">
      <BuildingDetail @onClose="showDetail = false" />
    </div>
  </Modal>
</template>

<script>
import TitleContentPanel from '@/components/TitleContentPanel/tabs.vue'
import Modal from '@/components/Modal'
import BuildingDetail from '@/components/ListView/BuildingDetail'
import { aggregate } from '@/api/aggregate'
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { SET_CURRENT_BUILDING_ID } from '@/store/mutation-types'
export default {
  name: 'Left1',
  components: { TitleContentPanel, Modal, BuildingDetail },
  setup: () => {
    const store = useStore()
    const dataList = ref([])
    const currentStreetId = computed(() => store.state.common.currentStreetId)
    const currentBuildingTag = computed(() => store.state.buildingResources.currentBuildingTag)
    const currentIndustryType = computed(() => store.state.industrialEconomy.currentIndustry)
    const tabValue = ref(1)
    const showDetail = ref(false)

    // const industryMap = {
    //   0: '所有产业',
    //   1: '总部经济',
    //   2: '数字经济',
    //   3: '现代金融',
    //   4: '商贸商务',
    //   5: '航运贸易',
    //   6: '文化旅游',
    //   7: '其它'
    // }

    // const getCurrentCount = item => {
    //   switch (tabValue.value) {
    //     case 1:
    //       return currentIndustryType.value === '0'
    //         ? item.stats.regCpyCount
    //         : item.stats[industryMap[currentIndustryType.value]] || 0
    //     case 2:
    //       return currentIndustryType.value === '0'
    //         ? item.stats.enterCpyCount
    //         : item.stats[industryMap[currentIndustryType.value]] || 0
    //     default:
    //       return currentIndustryType.value === '0'
    //         ? item.stats.regEnterCpyCount
    //         : item.stats[industryMap[currentIndustryType.value]] || 0
    //   }
    // }

    const getCurrentCount = item => {
      switch (tabValue.value) {
        case 1:
          return item.stats.regCpyCount
        case 2:
          return item.stats.enterCpyCount
        default:
          return item.stats.regEnterCpyCount
      }
    }

    const options = {}

    const fetchData = (options = {}) => {
      const { streetId, buildingType, currentIndustryType, sort } = options

      const params = {
        cmdList: [
          { $match: {} },
          { $match: { 'data.buildingTypeLabel': { $in: ['商务楼宇'] } } },
          { $sort: { 'stats.regCpyCount': -1 } },
          { $limit: 5 }
        ],
        templateName: 'building_information'
      }

      if (streetId) {
        params.cmdList[0].$match['data.street'] = `NumberLong(${streetId})`
      }

      if (buildingType) {
        params.cmdList[0].$match['data.buildingType'] = buildingType
      }

      if (currentIndustryType) {
        params.cmdList[0].$match['data.industryType'] = currentIndustryType
      }

      if (sort) {
        params.cmdList[2].$sort = sort
      }

      aggregate(params).then(res => {
        dataList.value = res.data
      })
    }

    fetchData()

    watch(currentIndustryType, type => {
      if (type && type !== '0') {
        options.currentIndustryType = { $in: [type] }
        // options.sort = { [`stats.${industryMap[currentIndustryType.value]}`]: -1 }
      } else {
        delete options.currentIndustryType
      }
      fetchData(options)
    })

    watch(currentBuildingTag, tag => {
      if (tag) {
        options.buildingType = { $in: [`${tag}`] }
      } else {
        delete options.buildingType
      }
      fetchData(options)
    })

    watch(currentStreetId, streetId => {
      options.streetId = streetId
      fetchData(options)
    })

    watch(tabValue, val => {
      if (val === 1) {
        options.sort = { 'stats.regCpyCount': -1 }
      } else if (val === 2) {
        options.sort = { 'stats.enterCpyCount': -1 }
      } else if (val === 3) {
        options.sort = { 'stats.regEnterCpyCount': -1 }
      }
      fetchData(options)
    })

    const handleTabClick = value => {
      tabValue.value = value
    }

    const tabs = [
      {
        label: '注册数Top5',
        value: 1
      },
      {
        label: '入驻数Top5',
        value: 2
      },
      {
        label: '入驻且注册数Top5',
        value: 3
      }
    ]

    const handleItemClick = item => {
      store.commit(SET_CURRENT_BUILDING_ID, item.id)
      showDetail.value = true
    }

    return {
      dataList,
      tabValue,
      showDetail,
      tabs,
      handleTabClick,
      getCurrentCount,
      handleItemClick
    }
  }
}
</script>

<style lang="less" scoped>
.left-1-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 0 20px;
  cursor: pointer;

  & > ul {
    width: 100%;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .index-label {
        display: flex;
        align-items: center;
        height: 45px;
        flex: 1;
        width: 1px;

        .top3 {
          background-color: #1e75ff !important;
        }

        & > .index {
          display: inline-block;
          width: 22px;
          height: 22px;
          line-height: 22px;
          border-radius: 50%;
          background-color: #999;
          text-align: center;
          margin-right: 10px;
        }

        & > .label {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.item-detail {
  width: 1180px;
  height: 950px;
  background-color: #0b0f1a;
  padding: 20px;
  overflow: hidden;
}
</style>
