import { useGeoJSON } from '@/hooks'
import { scaleLinear, scaleSequential } from 'd3-scale'
import { interpolateRdYlGn } from 'd3-scale-chromatic'
import { color } from 'd3-color'
import { watch, computed } from 'vue'
import useStreetLine from '@/hooks/mapLayer/useStreetLine'
import store from '@/store'
import { SET_MAP_BUILDING_NAME, SET_HOVER_BUILDING_NAME } from '@/store/mutation-types'
export default (map, AMap, loca, { buildingData, streetData, visible = true }) => {
  const tax = { min: 0, max: buildingData.maxTax || 1000 }
  const currentStreetId = computed(() => store.state.common.currentStreetId)
  const hoverBuildingId = computed(() => store.state.common.hoverBuildingName)
  const currentIndustryType = computed(() => store.state.industrialEconomy.currentIndustry)
  const currentBuildingTag = computed(() => store.state.buildingResources.currentBuildingTag)
  const defaultRange = [500, 4000]
  const streetRange = [100, 2500]

  loca.ambLight = {
    intensity: 0.4,
    color: '#ced5ed'
  }
  loca.dirLight = {
    intensity: 0.02,
    color: '#666',
    target: [10, 10, 10],
    position: [0, -1, 0.1]
  }
  loca.pointLight = {
    color: 'rgb(155,173,80)',
    position: [120.357907, 36.066541, 20000],
    intensity: 2.2,
    // 距离表示从光源到光照强度为 0 的位置，0 就是光不会消失。
    distance: 40000
  }

  const commonFilter = ({
    streetId, // 根据街道ID过滤
    industryType = [], // 根据楼宇标签过滤
    buildingTag = [] // 重点楼宇，亿元楼宇
  }) => {
    let streetFlag = true
    let industryFlag = true
    let tagFlag = true

    if (!currentStreetId.value) {
      streetFlag = true
    } else {
      streetFlag = currentStreetId.value === streetId
    }

    if (currentIndustryType.value === '0') {
      industryFlag = true
    } else {
      industryFlag = industryType.includes(String(currentIndustryType.value))
    }

    if (currentBuildingTag.value) {
      // 亿元楼宇
      if (currentBuildingTag.value === 1) {
        tagFlag = buildingTag.includes('亿元楼宇')
      } else if (currentBuildingTag.value === 2) {
        tagFlag = buildingTag.includes('重点楼宇')
      } else if (currentBuildingTag.value === 3) {
        tagFlag = buildingTag.includes('特色楼宇')
      }
    }

    return streetFlag && industryFlag && tagFlag
  }

  // 高度和颜色映射函数
  const heightScale = scaleLinear()
    .domain([tax.min, tax.max])
    .range(defaultRange)

  const colorScale = scaleSequential(t => interpolateRdYlGn(1 - t))
    .domain([tax.min, tax.max])
    .nice()

  // 获取颜色和高度的方法
  const getColor = feature => {
    const currentCount = feature.coordinates[0].properties.totalTax
    return colorScale(currentCount < 0 ? 0 : currentCount)
  }

  const getHeight = feature => {
    const currentCount = feature.coordinates[0].properties.totalTax
    const streetId = feature.coordinates[0].properties.streetId
    const industryType = feature.coordinates[0].properties.industryType
    const buildingTag = feature.coordinates[0].properties.buildingTypeLabel

    if (commonFilter({ streetId, industryType, buildingTag })) {
      return heightScale(currentCount < 0 ? 0 : currentCount)
    } else {
      return 0
    }
  }

  const geo = useGeoJSON({ data: buildingData })

  const pl = new window.Loca.HexagonLayer({
    loca,
    zIndex: 10,
    opacity: 0.8,
    visible,
    zooms: [2, 22]
    // acceptLight: true
    // shinniness: 0,
    // cullface: 'none',
    // depth: true,
    // hasSide: true,
  })

  pl.setSource(geo)

  const totalTaxConfig = {
    unit: 'meter',
    radius: 60,
    gap: 0,
    altitude: 0,
    height: (index, feature) => {
      return getHeight(feature)
    },
    topColor: (index, feature) => {
      return getColor(feature)
    },
    sideTopColor: (index, feature) => {
      return getColor(feature)
    },
    sideBottomColor: (index, feature) => {
      return getColor(feature)
    }
  }

  pl.setStyle(totalTaxConfig)

  const streetLine = useStreetLine(map, AMap, streetData)
  const show = () => {
    streetLine.show(1000)
    map.setPitch(40, false, 3000)
    pl.show(1000)
    pl.addAnimate(
      {
        key: 'height',
        value: [0, 1],
        duration: 1000,
        easing: 'CubicInOut'
      },
      () => {}
    )

    // loca.viewControl.addAnimates({
    //   pitch: {
    //     value: 50,
    //     control: [
    //       [0, 20],
    //       [1, 0]
    //     ],
    //     timing: [0, 0, 0.8, 1],
    //     duration: 3000
    //   }
    // })
    // loca.animate.start()
  }

  const hide = () => {
    streetLine.hide(1000)
    pl.hide(100)
  }

  const infoWindow = new AMap.InfoWindow({ isCustom: true, offset: [0, -20] })
  const getInfoContent = (geojson, type = 1) => {
    const { buildingName, regLastYearTax } = geojson.coordinates[0].properties
    return `
        <div class="custom-window-info">
          <div class="info-item street-name">${buildingName}</div>
          <div class="info-item building">税收产值：${regLastYearTax}万元</div>
        </div>
      `
  }

  map.on('mousemove', e => {
    const totalFeat = pl.queryFeature(e.pixel.toArray())

    if (totalFeat) {
      const { lng, lat } = e.lnglat
      infoWindow.setContent(getInfoContent(totalFeat))
      infoWindow.open(map, [lng, lat])

      // 列表交互
      const { id } = totalFeat.coordinates[0].properties
      store.commit(SET_MAP_BUILDING_NAME, id)
      store.commit(SET_HOVER_BUILDING_NAME, '')

      pl.setStyle({
        ...totalTaxConfig,
        topColor: (index, feature) => {
          if (feature.coordinates[0].lnglat[0] === totalFeat.coordinates[0].lnglat[0]) {
            return color('#f00')
              .brighter(5)
              .toString()
          }
          return getColor(feature)
        },
        sideTopColor: (index, feature) => {
          if (feature.coordinates[0].lnglat[0] === totalFeat.coordinates[0].lnglat[0]) {
            console.log(
              color(getColor(feature))
                .brighter(2)
                .toString()
            )
            return color('#f00')
              .brighter(2)
              .toString()
          }
          return getColor(feature)
        },
        sideBottomColor: (index, feature) => {
          if (feature.coordinates[0].lnglat[0] === totalFeat.coordinates[0].lnglat[0]) {
            return color('#f00')
              .brighter(2)
              .toString()
          }
          return getColor(feature)
        }
      })
    } else {
      infoWindow.hide()
      pl.setStyle(totalTaxConfig)
    }
  })

  const getBuildingDataById = id => {
    return buildingData.features.find(d => d.properties.id === id)
  }

  // 根据数据重新渲染柱子
  const unwatch2 = watch(
    [currentStreetId, currentIndustryType, currentBuildingTag],
    ([streetId, industryType, buildingTag]) => {
      if (!streetId) {
        heightScale.range(defaultRange)
      } else {
        heightScale.range(streetRange)
      }

      pl.addAnimate(
        {
          key: 'height',
          value: [1, 0],
          duration: 1000,
          easing: 'CubicInOut'
        },
        () => {
          pl.setStyle(totalTaxConfig)
          pl.addAnimate({
            key: 'height',
            value: [0, 1],
            duration: 500,
            easing: 'CubicInOut'
          })
        }
      )
    }
  )

  // 列表hove时触发地图
  const unwatch = watch(hoverBuildingId, buildingId => {
    const obj = getBuildingDataById(buildingId)
    if (obj) {
      const xy = map.lngLatToContainer(obj.geometry.coordinates)

      const totalFeat = pl.queryFeature([xy.x, xy.y])

      if (totalFeat) {
        const [lng, lat] = obj.geometry.coordinates
        infoWindow.setContent(getInfoContent(totalFeat))
        infoWindow.open(map, [lng, lat])

        pl.setStyle({
          ...totalTaxConfig,
          topColor: (index, feature) => {
            if (feature.coordinates[0].lnglat[0] === totalFeat.coordinates[0].lnglat[0]) {
              return color('#f00')
                .brighter(5)
                .toString()
            }
            return getColor(feature)
          },
          sideTopColor: (index, feature) => {
            if (feature.coordinates[0].lnglat[0] === totalFeat.coordinates[0].lnglat[0]) {
              return color('#f00')
                .brighter(5)
                .toString()
            }
            return getColor(feature)
          },
          sideBottomColor: (index, feature) => {
            if (feature.coordinates[0].lnglat[0] === totalFeat.coordinates[0].lnglat[0]) {
              return color('#f00')
                .brighter(5)
                .toString()
            }
            return getColor(feature)
          }
        })
      } else {
        infoWindow.hide()
      }
    }
  })

  return {
    show,
    hide,
    unwatch,
    unwatch2
  }
}
