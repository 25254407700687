<template>
  <div class="industrial-economy-wrapper">
    <LCRLayout>
      <template #left>
        <LeftPart />
      </template>

      <template #center>
        <CenterPart />
      </template>

      <template #right>
        <RightPart />
      </template>
    </LCRLayout>
  </div>
</template>

<script>
// import SubMenu from '@/components/SubMenu'
import LCRLayout from '@/layout/LCRLayout'
import CenterPart from './CenterPart'
import LeftPart from './LeftPart'
import RightPart from './RightPart'
import useLoading from '@/hooks/useLoading'

export default {
  name: 'HomeLayout',
  components: { LCRLayout, LeftPart, CenterPart, RightPart },
  setup: () => {
    const { hidLoading } = useLoading()
    hidLoading()

    return {}
  }
}
</script>

<style lang="less" scoped>
.industrial-economy-wrapper {
  height: 100%;
}
</style>
