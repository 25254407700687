<template>
  <div class="left-part-wrapper">
    <div class="street-list">
      <!-- <component :is="currentList" :sortData="sortData" /> -->
      <BuildingList />
    </div>
  </div>
</template>

<script>
import BuildingList from '@/components/ListView/BuildingList'

export default {
  name: 'RightPart',
  components: { BuildingList },

  setup: () => {
    return {}
  }
}
</script>

<style lang="less" scoped>
.left-part-wrapper {
  height: 100%;

  .pie-chart {
    height: 100%;
  }

  .street-list {
    height: 100%;
  }
}
</style>
