<template>
  <div class="center-part-wrapper">
    <!-- mapStyle="amap://styles/darkblue" -->
    <AMap
      mapStyle="amap://styles/3c5b5047c37621e5b97d427e94ccc67f"
      :aMapKey="AMAP_KEY"
      :zoom="13.2"
      :showLabel="false"
      :pitch="0"
      viewMode="3D"
      :plugins="['GeoJSON']"
      :center="[120.357907, 36.066541]"
      :isReady="isReady"
      @mapLoaded="handleMapLoaded"
    />

    <div class="menu-btn">
      <div class="menu">
        <SubMenuWithSelect />
      </div>
      <div class="btn">
        <CustomRadioGroup :dataList="btnDataMap.company" @onChange="handleBtnChange" />
      </div>

      <MapLegend :max="mapBuildingGeojson.maxTax" />
    </div>
  </div>
</template>

<script>
import AMap from '@/components/AMap'
import CustomRadioGroup from '@/components/CustomRadioGroup'
import { AMAP_KEY } from '@/config/commonConfig'
import { ref, onBeforeUnmount, computed } from 'vue'
import { getStreetPolygon } from '@/api/map'
import { useLoca } from '@/hooks'
import { btnDataMap } from '@/utils/locaData'
import useBuilding from './hooks/useBuilding.js'
import SubMenuWithSelect from '@/views/buildingResources/SubMenuWithSelect.vue'
import { useStore } from 'vuex'
import { SET_CURRENT_INDUSTRY } from '@/store/mutation-types'
import MapLegend from '@/components/MapLegend'

export default {
  name: 'CenterPart',
  components: { AMap, CustomRadioGroup, SubMenuWithSelect, MapLegend },
  setup: () => {
    const store = useStore()
    const isReady = ref(false)
    const btnLabel = ref(btnDataMap.company[0].label)
    const mapBuildingGeojson = computed(() => store.state.common.mapBuildingGeojson)
    const partMap = {}
    let locaObj

    const handleMapLoaded = ({ map, AMap }) => {
      const getMapBuildingGeojson = store.dispatch('getMapBuildingGeojson')

      Promise.all([getStreetPolygon(), getMapBuildingGeojson]).then(
        ([streetData, buildingData]) => {
          const { loca } = useLoca(map)
          locaObj = loca

          partMap.building = useBuilding(map, AMap, loca, {
            streetData,
            buildingData
          })

          partMap.building.show()

          isReady.value = true
        }
      )
    }

    const handleBtnChange = label => {
      btnLabel.value = label
      store.commit(SET_CURRENT_INDUSTRY, label)
    }

    onBeforeUnmount(() => {
      store.commit(SET_CURRENT_INDUSTRY, '0')
      // 页面离开时需要销毁，否则绘制有问题
      locaObj && locaObj.animate.stop()
      locaObj && locaObj.destroy()

      Object.keys(partMap).forEach(partName => {
        const layer = partMap[partName]
        layer.unwatch && layer.unwatch()
        layer.unwatch2 && layer.unwatch2()
      })
    })

    return {
      isReady,
      AMAP_KEY,
      handleMapLoaded,

      btnDataMap,
      mapBuildingGeojson,
      handleBtnChange
    }
  }
}
</script>

<style lang="less" scoped>
.center-part-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;

  .menu-btn {
    width: 100%;
    .menu {
      // height: 70px;
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 4px;
    }

    .btn {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
}
</style>
